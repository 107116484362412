import React from "react";
import pt from "prop-types";
import { combine } from "../../../helpers/styles";
import * as styles from "./styles.module.scss";

const Text = ({
  as: As,
  type,
  variant,
  style,
  className,
  children,
  ...props
}) => (
  <As
    className={combine(styles[type], styles[variant], styles[style], className)}
    style={style}
    {...props}
  >
    {children}
  </As>
);

Text.propTypes = {
  as: pt.string,
  type: pt.string,
  variant: pt.string,
  style: pt.string,
  children: pt.node.isRequired,
  className: pt.string,
};

Text.defaultProps = {
  as: "p",
  type: "copy",
  variant: "",
  className: "",
};

export default Text;
