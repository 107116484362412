import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pt from "prop-types";
import React from "react";
import { combine } from "../../../helpers/styles";
import "../../../utils/font-awesome";
import * as styles from "./styles.module.scss";

const Icon = ({ name, size, className, onClick, type, color, icon }) => {
  if (!name && !icon) {
    return null;
  }

  name = name?.toLowerCase();

  return icon ? (
    icon?.file?.contentType === "image/svg+xml" && icon?.svg?.content ? (
      <div
        dangerouslySetInnerHTML={{ __html: icon?.svg?.content }}
        className={combine(className, styles.svgIcon)}
        style={{color: color}}
      />
    ) : (
      <img src={icon?.file?.url} className={className} />
    )
  ) : onClick ? (
    <button className={styles.button} onClick={onClick}>
      <FontAwesomeIcon
        icon={[type, name]}
        size={size}
        className={className}
        color={color}
      />
    </button>
  ) : (
    <FontAwesomeIcon
      icon={[type, name]}
      size={size}
      className={className}
      color={color}
    />
  );
};

Icon.propTypes = {
  name: pt.string.isRequired,
  size: pt.string,
  className: pt.string,
  onClick: pt.func,
  type: pt.string,
  color: pt.string,
  icon: pt.object,
};

Icon.defaultProps = {
  className: "",
  size: "1x",
  type: "fab",
  color: "white",
  icon: null,
};

export default Icon;
