import React from 'react';
import pt from 'prop-types';
import * as styles from './styles.module.scss'

const Iframe = ({title, url, height, mobileHeight}) => {  
  return <iframe src={url} title={title} className={styles.iframe} style={{'--height': `${height}px`, '--mobileHeight': `${mobileHeight}px`}}/>
};

Iframe.propTypes = {
  title: pt.string,
  url: pt.string,
  height: pt.number,
  mobileHeight: pt.number,
};
Iframe.defaultProps = {
  title: "",
  url: "",
  height: 300,
  mobileHeight: 400,
};

export default Iframe;
