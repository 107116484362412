import React from "react";
import Grid from "./grid";
import Hero from "./hero";
import LinearGrid from "./linearGrid";
import Iframe from "./iframe";
import Map from "./map";
import Multimedia from "./multimedia";
import RichText from "./richText";
import TeaserWithIcon from "./teaserWithIcon";
import TeaserWithImage from "./teaserWithImage";
import TestimonialItem from "./testimonialItem";
import TextVisual from "./textVisual";

//
// Content type - Component map
//
export const dynamicComponents = {
  ContentfulTeaserWithIconComponent: TeaserWithIcon,
  ContentfulTeaserWithImageComponent: TeaserWithImage,
  ContentfulTextVisualComponent: TextVisual,
  ContentfulMultimedia: Multimedia,
  ContentfulMapComponent: Map,
  ContentfulHeroComponent: Hero,
  ContentfulGridComponent: Grid,
  ContentfulTestimonial: TestimonialItem,
  ContentfulTextComponent: RichText,
  ContentfulLinearGridComponent: LinearGrid,
  ContentfulIframeComponent: Iframe,
};

export const renderDynamicComponents = (components) => {
  return components?.map((component, index) =>
    renderDynamicComponent(component)
  );
};

export const renderDynamicComponent = (component) => {
  if (!component.internal) return null;
  const DynamicComponent = dynamicComponents[component.internal.type];
  return DynamicComponent ? <DynamicComponent {...component} /> : null;
};
