import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import pt from 'prop-types';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { combine } from '../../helpers/styles';
import Link, { linkProptypes } from '../ui/link';
import Text from '../ui/text';
import * as styles from './styles.module.scss';

const Hero = ({
  tagline,
  headline,
  headlineColor,
  backgroundImage,
  withOverlay,
  link,
  backgroundGradientColor1,
  backgroundGradientColor2,
  alignment,
}) => {
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  useEffect(() => {
    if (backgroundGradientColor1) {
      document?.documentElement?.style?.setProperty(
        '--bgGradientColor1',
        backgroundGradientColor1
      );
    }
    if (backgroundGradientColor2) {
      document?.documentElement?.style?.setProperty(
        '--bgGradientColor2',
        backgroundGradientColor2
      );
    }
  }, []);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      className={styles.hero}
    >
      {withOverlay && (
        <div
          className={combine(
            styles.colorOverlay,
            backgroundGradientColor1 &&
              backgroundGradientColor2 &&
              styles.twoColors
          )}
        ></div>
      )}
      <div
        className={combine(
          styles.innerContainer,
          alignment && styles[alignment]
        )}
      >
        <Container
          className={styles.heroContainer}
          style={{ color: headlineColor }}
        >
          {tagline && (
            <Text type="h4" variant="bold">
              {tagline}
            </Text>
          )}
          {headline && (
            <Text as="h2" type="h1" variant="bold">
              {headline}
            </Text>
          )}
          {link && (
            <Link {...link} className={styles.link}>
              <Text as="span" type="h4" variant="bold">
                {link.text}
              </Text>
            </Link>
          )}
        </Container>
      </div>
    </BackgroundImage>
  );
};

Hero.propTypes = {
  tagline: pt.string,
  headline: pt.string,
  backgroundImage: pt.object,
  withOverlay: pt.bool,
  link: pt.shape(linkProptypes),
  alignment: pt.oneOf(['left', 'center', 'right']),
  backgroundGradientColor1: pt.string,
  backgroundGradientColor2: pt.string,
  headlineColor: pt.string,
};

Hero.defaultProps = {
  tagline: '',
  headline: '',
  backgroundImage: null,
  withOverlay: false,
  link: null,
  alignment: 'center',
  backgroundGradientColor1: 'rgba(0,0,0,0.4)',
  backgroundGradientColor2: 'rgba(0,0,0,0.4)',
  headlineColor: '#FFF',
};

export default Hero;
