import React from 'react'
import pt from 'prop-types'
import { Container } from 'react-bootstrap'
import LinearGridItem from './linearGridItem'
import * as styles from './styles.module.scss'


const LinearGrid = ({items}) => {
  return (
    <Container>
      <div className={styles.container}>
        {items?.map(item => 
          <LinearGridItem key={item.title} {...item}/>  
        )} 
      </div>
    </Container>
  )
}

LinearGrid.propTypes = {
  items: pt.array
}
LinearGrid.defaultProps = {
  items: []
}



export default LinearGrid