import pt from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponent } from "..";
import { combine } from "../../helpers/styles";
import LinearGrid from "../linearGrid";
import MasonryComponent from "../masonry";
import Slider from "../ui/slider";
import * as styles from "./styles.module.scss";

const Grid = ({
  items,
  colSpan,
  fullWidth,
  noMargins,
  sliderOnMobile,
  arrowsColor,
  type,
  className,
}) => {

  const renderGrid = () => {
    if (type === "Linear") {
      return (
        <LinearGrid items={items} />
      )
    }

    if (type === "Masonry") {
      return (
        <MasonryComponent items={items}/>
      )
    }

    return (
      <Container
        className={combine(
          styles.gridContainer,
          fullWidth && styles.fullWidth,
          noMargins && styles.noMargins,
          className
        )}
        fluid={fullWidth}
      >
        {sliderOnMobile ? (
          <>
            <div className={styles.containerSm}>
              <Slider
                className={styles.mobileSlider}
                dots={false}
                arrowsColor={arrowsColor}
                sliderOnMobile
              >
                {items.map((item) => renderDynamicComponent(item))}
              </Slider>
            </div>
            <div className={styles.containerLg}>
              <Row className={styles.xCentered}>
                {items.map((item, idx) => (
                  <Col key={idx} sm={12} md={colSpan}>
                    {renderDynamicComponent(item)}
                  </Col>
                ))}
              </Row>
            </div>
          </>
        ) : (
          <Row className={styles.xCentered}>
            {items.map((item, idx) => (
              <Col key={idx} sm={12} md={colSpan}>
                {renderDynamicComponent(item)}
              </Col>
            ))}
          </Row>
        )}
      </Container>
    )
  }
  return (
    renderGrid()
  );
};

Grid.propTypes = {
  items: pt.array,
  colSpan: pt.number,
  fullWidth: pt.bool,
  type: pt.oneOf(["Default", "Linear", "Masonry"])
};

Grid.defaultProps = {
  items: [],
  colSpan: 3,
  fullWidth: false,
  type: "Default"
};

export default Grid;
