import React from 'react';
import pt from 'prop-types';
import { Container } from 'react-bootstrap';
import * as styles from './styles.module.scss';
import MasonryItem from './masonryItem';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { combine } from '../../helpers/styles';

const MasonryComponent = ({ items }) => {
  return (
    <Container>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 575: 2 }}
        className={combine(styles.masonryContainer, styles.withYMargins)}
      >
        <Masonry gutter="30px">
          {items?.map((item) => (
            <MasonryItem {...item} />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <div className={combine(styles.grid, styles.withYMargins)}>
        {items?.map((item) => {
          const props = { ...item, isMobile: true };
          return <MasonryItem {...props} />;
        })}
      </div>
    </Container>
  );
};

MasonryComponent.propTypes = {
  items: pt.array,
};
MasonryComponent.defaultProps = {
  items: [],
};

export default MasonryComponent;
