import React, { useEffect } from 'react';
import pt from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import * as styles from './styles.module.scss';
import Text from '../../ui/text';
import { combine } from '../../../helpers/styles';

const MasonryItem = ({ title, image, fontColor, textWithShadow, isMobile }) => {
  const ctfImage = getImage(image);
  const bgImage = convertToBgImage(ctfImage);

  useEffect(() => {
    document?.documentElement?.style?.setProperty(
      '--masonry-grid-text-color',
      fontColor
    );
  }, []);

  return !isMobile ? (
    <div className={styles.relative}>
      <GatsbyImage image={ctfImage} />
      <Text className={combine(styles.noMargins, styles.centeredText, styles.text, textWithShadow && styles.withShadow, styles.masonryText)}>
        {title}
      </Text>
    </div>
  ) : (
    <BackgroundImage
      Tag="div"
      {...bgImage}
      className={styles.container}
    >
      <Text className={combine(styles.noMargins, styles.centeredText, styles.text, textWithShadow && styles.withShadow)}>
        {title}
      </Text>
    </BackgroundImage>
  );
};

MasonryItem.propTypes = {
  title: pt.string,
  image: pt.object,
  fontColor: pt.string,
  textWithShadow: pt.bool,
  isMobile: pt.bool,
};
MasonryItem.defaultProps = {
  title: '',
  image: null,
  fontColor: '#FFFFFF',
  textWithShadow: false,
  isMobile: false,
};

export default MasonryItem;
