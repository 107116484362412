import { GatsbyImage, getImage } from "gatsby-plugin-image";
import pt from "prop-types";
import React from "react";
import { combine } from "../../helpers/styles";
import Link, { linkProptypes } from "../ui/link";
import Text from "../ui/text";
import * as styles from "./styles.module.scss";
import { renderDynamicComponent } from "../index";

const TeaserWithImage = ({ image, tagline, headline, headlineColor, textBelowHeadline, link, alignment }) => {
  const ctfImage = getImage(image);

  return (
    <div className={styles.teaserItem}>
      <GatsbyImage image={ctfImage} alt={tagline} />
      <div className={combine(styles.textContainer, styles[alignment])}>
        {tagline && (
          <Text
            as="p"
            className={combine(styles.tagline, image && styles.withImage)}
          >
            {tagline}
          </Text>
        )}
        {headline && (
          <Text as="h2" type="h2" variant="bold" className={styles.headline} style={{color: headlineColor}}>
            {headline}
          </Text>
        )}
        {textBelowHeadline && renderDynamicComponent(textBelowHeadline)}
        {link && (
          <Link {...link} className={styles.link} withIcon>
            <Text as="span" type="h4" variant="bold">
              {link.text}
            </Text>
          </Link>
        )}
      </div>
    </div>
  );
};
TeaserWithImage.propTypes = {
  tagline: pt.string,
  headline: pt.string,
  image: pt.object,
  link: pt.shape(linkProptypes),
  alignment: pt.oneOf(["left", "right", undefined]),
  headlineColor: pt.string
};
TeaserWithImage.defaultProps = {
  tagline: "",
  headline: "",
  image: {},
  link: null,
  headlineColor: "#000"
};

export default TeaserWithImage;
