import React, { useEffect } from 'react';
import pt from 'prop-types'
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Text from '../../ui/text';
import * as styles from './styles.module.scss';
import { combine } from '../../../helpers/styles';

const LinearGridItem = ({ title, image, fontColor, textWithShadow}) => {
  const ctfImage = getImage(image);
  const bgImage = convertToBgImage(ctfImage);

  useEffect(() => {
    document?.documentElement?.style?.setProperty(
      '--linear-grid-text-color',
      fontColor
    );
  }, []);
  
  return (
    <BackgroundImage
      Tag="div"
      {...bgImage}
      className={styles.container}
    >
      <Text className={combine(styles.noMargins, styles.centeredText, styles.text, textWithShadow && styles.withShadow)}>
        {title}
      </Text>
    </BackgroundImage>
  );
}

LinearGridItem.propTypes = {
  title: pt.string,
  image: pt.object,
  fontColor: pt.string,
  textWithShadow: pt.bool,
}
LinearGridItem.defaultProps = {
  title: "",
  image: null,
  fontColor: "#FFFFFF",
  textWithShadow: false,
}

export default LinearGridItem